import React, { useState, useEffect, Fragment } from "react"
import Layout from "../layout/Layout"
import axios from "axios";

import API from "../utils/API"
import { userComplete } from "../user/AccountPage"
import uuid from 'uuid-random'
import UploadTable from "./UploadTable"
import UploadMail from "./UploadMail"
import { Prompt } from 'react-router'

import { Typography, Button, Link, List, ListItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'

const path = require('path')


// we probably could do better
function MissingData({ isBillingValid, isUserValid }) {
    return (
        <div>
            {
                !isBillingValid && isUserValid &&
                <Typography variant="h4" style={{ marginTop: 20 }}>
                    Afin d'effectuer une commande, veuillez indiquer un moyen de paiement valide&nbsp;
                    <Link href="/billing" style={{ cursor: "pointer" }}>ici</Link>.
                </Typography>
            }

            {
                !isUserValid && isBillingValid &&
                <Typography variant="h4" style={{ marginTop: 20 }}>
                    Afin d'effectuer une commande, veuillez remplir vos informations personnelles&nbsp;
                    <Link href="/account" style={{ cursor: "pointer" }}>ici</Link>.
                </Typography>
            }

            {
                !isUserValid && !isBillingValid &&
                <Typography variant="h4" style={{ marginTop: 20 }}>
                    Afin d'effectuer une commande, veuillez remplir vos informations personnelles&nbsp;
                    <Link href="/account" style={{ cursor: "pointer" }}>ici</Link>&nbsp;et votre moyen de paiement&nbsp;<Link href="/billing" style={{ cursor: "pointer" }}>ici</Link>.
                </Typography>
            }
        </div>
    )
}



// check progress every second (this should be changed into websockets at some point)
function ProgressCheck(props) {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1)
            props.progressCheckFunction()
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (<div />)
}


// the main TranscodePage, warning: IT HAS NOT BEEN CLEANED UP!! (we changed from synchronous to asynchronous transcode)
export default class TranscodePage extends React.Component {
    state = {
        workflow: {},
        orderId: "",
        finalZipURL: "",
        currentState: "ready",
        isLoaded: false,
        isUserValid: false,
        isBillingValid: false,
        selectedFiles: [] // {id:UUID, file:File, status:String, progress:int, downloadUrl:url, jobId:uuid}
    }

    constructor(props) {
        super(props)

        this.keepOrderAlive = this.keepOrderAlive.bind(this)
        this.onValidateOrder = this.onValidateOrder.bind(this)
        this.updateTranscodingStatus = this.updateTranscodingStatus.bind(this)
    }


    async componentDidMount() {
        const workflowId = localStorage.getItem("workflow")
        const workflow = await API.getWorkflow(workflowId)
        const validBilling = await API.isValid()
        const { data } = await API.getPersonalData()

        console.log("Is valid: " + JSON.stringify(validBilling.data))
        this.setState({
            isLoaded: true,
            workflow: workflow.data,
            isUserValid: userComplete(data),
            isBillingValid: validBilling.data.valid
        })
    }


    // handles premature leaving if uploading
    componentDidUpdate() {
        if (this.isUploading())
            window.onbeforeunload = () => true
        else
            window.onbeforeunload = undefined
    }


    // 1- first selects file from disk, adds them to the selected files state
    onSelectFile = e => {
        const files = e.target.files
        if (files && files.length > 0) {
            var i = 0;
            while (i < files.length) {
                var file = files[i];
                this.state.selectedFiles.push({ id: uuid(), file: file, status: "ready" })
                console.log(file.name);
                i++;
            }

            this.setState({ selectedFiles: this.state.selectedFiles })
        }
    }


    // 2- called when clicking on validate button, it first registers the order
    // it then triggers uploading of every file using multipart 
    async onValidateOrder(emails) {
        const files = this.state.selectedFiles.map(f => { return { id: f.id, name: f.file.name, type: f.file.type } })
        const workflowId = this.state.workflow.Identifier
        const response = await API.requestTranscodeOrder(files, workflowId, emails)
        const results = response.data.results

        console.log("Order result: " + JSON.stringify(response.data))

        this.setState({ currentState: "uploading", orderId: response.data.orderId })

        // we maybe could enforce the id
        for (var i = 0; i < this.state.selectedFiles.length; i++)
            await this.uploadFileWithMultipart(this.state.selectedFiles[i].id, results[i])
    }


    // 3- called after validating order, uploads the given file using multipart, it first initializes the progress
    // it then iterates on each part, generates an upload url for that part, and puts the blob using the url
    uploadFileWithMultipart = async (id, result) => {
        this.updateFileStatus(id, "uploading")

        const fileObj = this.state.selectedFiles.find(e => e.id == id)

        console.log(`Uploading multipart: ${JSON.stringify(fileObj)} (Result: ${JSON.stringify(result)})`)

        const FILE_CHUNK_SIZE = 1000000000 // 1G
        const fileSize = fileObj.file.size
        const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1
        let promisesArray = []
        let start, end, blob

        this.initFileProgressMultipart(id, NUM_CHUNKS)

        // first push everything into the promise array
        for (let index = 1; index < NUM_CHUNKS + 1; index++) {
            start = (index - 1) * FILE_CHUNK_SIZE
            end = (index) * FILE_CHUNK_SIZE
            blob = (index < NUM_CHUNKS) ? fileObj.file.slice(start, end) : fileObj.file.slice(start)

            // (1) Generate presigned URL for each part
            let getUploadUrlResp = await API.generateUploadPart(result.Key, index, result.UploadId)
            let { putURL } = getUploadUrlResp.data
            console.log('Presigned URL ' + index + ': ' + putURL + ' filetype ' + fileObj.file.type)

            // (2) Puts each file part into the storage server
            const options = {
                headers: { "Content-Type": fileObj.file.type },

                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    this.updateFileProgressMultipart(id, percentCompleted, index - 1)
                }.bind(this)
            }

            let uploadResp = axios.put(putURL, blob, options)
            promisesArray.push(uploadResp)
        }

        // now resolve all promises
        let resolvedArray = await Promise.all(promisesArray)

        let uploadPartsArray = []
        resolvedArray.forEach((resolvedPromise, index) => {
            uploadPartsArray.push({
                ETag: resolvedPromise.headers.etag,
                PartNumber: index + 1
            })
        })

        // (3) Calls the CompleteMultipartUpload endpoint in the backend server
        const uploadResult = await API.completeUpload(result.Key, uploadPartsArray, result.UploadId)
        console.log(`Finished uploading multi-part for ${JSON.stringify(fileObj)}, received from server: ${JSON.stringify(uploadResult.data)}`)
        await this.onUploadSucessful(id)
    }


    // 4- called when uploading is finished, it warns the server which triggers the asynchronous transcoding request
    // we might merge that with the multipart upload completion messages
    async onUploadSucessful(id) {
        var fileObj = this.state.selectedFiles.find(e => e.id == id)

        console.log(`Now warning server to move on to transcoding for ${fileObj.file.name} in order ${this.state.orderId}`)
        const { data } = await API.onUploadFinished(this.state.orderId, fileObj.file.name)

        console.log(`Transcoding request response ${JSON.stringify(data)}`)

        // this will trigger the transcoding *after* receiving upload status
        this.updateFileProgress(id, 0)
        this.updateFileStatus(id, "transcoding")
    }


    // 5- called at regular interval when transcoding, updates the status of the files of the order
    async updateTranscodingStatus() {
        if (this.state.orderId) {
            const { data } = await API.retrieveOrder(this.state.orderId)
            console.log("Order is: " + JSON.stringify(data))
            console.log(`Selected files are: ${JSON.stringify(this.state.selectedFiles)}`)
            data.inputs.forEach(i => {
                const fileObj = this.state.selectedFiles.find(f => f.id == i._id)
                fileObj.status = i.status
                if (i.progress)
                    fileObj.progress = i.progress
                fileObj.downloadUrl = i.downloadUrl
            })
            this.setState({ selectedFiles: this.state.selectedFiles })
        }
    }


    // keeps the order alive on the server side, we might use websockets
    // this is necessary because upload might be interrupted, or cancelled out
    async keepOrderAlive() {
        await API.keepAlive(this.state.orderId)
    }


    allTranscoded = () => this.state.selectedFiles.every(f => f.status == "transcoded")

    isTrancoding = () => this.state.selectedFiles.filter(f => f.status == "transcoding").length > 0

    isUploading = () => this.state.selectedFiles.some(f => f.status == "uploading")

    removeSelectedFile = f => this.setState({ selectedFiles: this.state.selectedFiles.filter(o => o.id != f.id) })

    render() {

        return (
            <Layout>
                <Prompt
                    when={this.isUploading()}
                    message='Are you sure you want to leave?' // not used??
                />
                <Typography variant="h2" style={{ marginTop: 30 }} className="HeaderText">
                    Votre option: {this.state.workflow.Name}
                </Typography>

                {
                    this.state.isLoaded &&
                    <MissingData isBillingValid={this.state.isBillingValid} isUserValid={this.state.isUserValid} />
                }

                <Button
                    component="label"
                    variant="contained"
                    disableRipple style={{ marginTop: "30px", height: "50px" }}
                    disabled={this.state.currentState != "ready" || !this.state.isBillingValid || !this.state.isUserValid || !this.state.isLoaded}>
                    <Typography variant="h5">
                        Choisissez vos fichiers
                        </Typography>
                    <input style={{ display: 'none' }} type="file" accept="*/*" onChange={this.onSelectFile} multiple />
                </Button>

                <UploadTable files={this.state.selectedFiles} onRemoveFile={this.removeSelectedFile} />

                <UploadMail
                    onValidateOrder={this.onValidateOrder}
                    isProcessingOrder={this.state.currentState != "ready"}
                    isOrderValid={this.state.selectedFiles.length > 0 && this.state.isBillingValid && this.state.isUserValid && this.state.isLoaded} />

                {
                    this.isTrancoding() &&
                    <ProgressCheck progressCheckFunction={this.updateTranscodingStatus} />
                }

                {
                    this.isUploading() &&
                    <ProgressCheck progressCheckFunction={this.keepOrderAlive} />
                }

            </Layout>)
    }


    // this is a bit annoying but makes sure that the state gets updated
    updateFileStatus = (id, newStatus) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.status = newStatus
        this.setState({ selectedFiles: this.state.selectedFiles })
    }

    updateFileProgress = (id, newProgress) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.progress = newProgress
        this.setState({ selectedFiles: this.state.selectedFiles })
    }

    initFileProgressMultipart = (id, size) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.progressMultiparts = new Array(size).fill(0)
        this.setState({ selectedFiles: this.state.selectedFiles })
    }

    updateFileProgressMultipart = (id, newProgress, partIndex) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.progressMultiparts[partIndex] = newProgress
        this.setState({ selectedFiles: this.state.selectedFiles })
    }

    updateFileJobId = (id, jobId) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.jobId = jobId
        this.setState({ selectedFiles: this.state.selectedFiles })
    }

    updateFileDownloadUrl = (id, url) => {
        var f = this.state.selectedFiles.find(e => e.id == id)
        f.downloadUrl = url
        this.setState({ selectedFiles: this.state.selectedFiles })
    }
}