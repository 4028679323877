import React, { useEffect, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import API from '../utils/API'
import CardSection from './CardSection'
import Typography from '@material-ui/core/Typography'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { CircularProgress, IconButton, Button, Box } from '@material-ui/core/'


// we forbid orders 1 month before the expiry date
function isNearExpiry(monthCard, yearCard) {
    const date = new Date()
    const yearNow = date.getFullYear()
    const monthNow = date.getMonth() + 1 // +1 because starts at 0
    return yearCard == yearNow && monthCard == monthNow
}


function isExpired(monthCard, yearCard) {
    const date = new Date()
    const yearNow = date.getFullYear()
    const monthNow = date.getMonth() + 1 // +1 because starts at 0
    return yearNow > yearCard || (yearCard == yearNow && monthNow > monthCard)
}


function CardDisplay({ card, onRemoveCard }) {
    return (
        <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{ margin: "20px auto 0px" }}>
                <Typography style={{ fontSize: "15px" }}>
                    Vous avez enregistré une carte se terminant par {card.last4} (expiration: {card.exp_month}/{card.exp_year})
                </Typography>
                <IconButton onClick={onRemoveCard} style={{ marginLeft: "10px" }} >
                    <DeleteForeverIcon style={{ width: "20px", height: "20px" }} />
                </IconButton>
            </Box>

            {
                isNearExpiry(card.exp_month, card.exp_year) &&
                <Typography variant="h5" style={{ color: "#cc0000" }}>
                    Attention, votre carte expire prochainement et empêche les nouvelles commandes.
                </Typography>
            }

            {
                isExpired(card.exp_month, card.exp_year) &&
                <Typography variant="h5" style={{ color: "#cc0000" }}>
                    Attention, votre carte est expirée.
                </Typography>
            }
        </Box>
    )
}


function CardEditForm({ handleSubmit }) {
    return (
        <Box alignItems="center" display="flex" justifyContent="center" m="auto" width="600px" marginTop="30px">
            <CardSection />
            <Button
                size="large"
                component="label"
                variant="contained"
                onClick={handleSubmit}
                disableRipple style={{ marginLeft: "20px" }}>
                Sauvegarder
                    </Button>
        </Box >

    )
}


class CardSetupForm extends React.Component {
    state = { card: undefined, hasCheckedCard: false }

    constructor(props) {
        super(props)

        this.onRemoveCard = this.onRemoveCard.bind(this)
        this.retrieveCard = this.retrieveCard.bind(this)
    }

    async componentDidMount() {
        await this.retrieveCard()
    }


    async retrieveCard() {
        const response = await API.retrieveCard()
        const cards = response.data.cards

        if (cards.length == 0)
            this.setState({ card: undefined, hasCheckedCard: true })
        else this.setState({ card: cards[0].card, hasCheckedCard: true })

        console.log("Cards are " + JSON.stringify(this.state.card))
    }


    handleSubmit = async (event) => {
        event.preventDefault();

        const intent = await API.requestSetupIntent()
        const { stripe, elements } = this.props

        if (!stripe || !elements)
            return;

        const result = await stripe.confirmCardSetup(intent.data.client_secret, {
            payment_method: {
                card: elements.getElement(CardElement)/*,
                billing_details: { name: 'Jenny Rosen' }*/ // warning, this was not caught while validating
            }
        })

        if (result.error) {
            console.log("Failure... " + JSON.stringify(result.error))
        } else {
            console.log("Success: " + JSON.stringify(result))
            await this.retrieveCard()
            await API.setDefaultCard()
        }

        this.setState({ hasCheckedCard: true })
    }


    async onRemoveCard() {
        if (this.state.card) {
            const { data } = await API.removeCard()
            if (data.status == "success")
                this.setState({ card: undefined })
            else {
                this.setState({ error: data.errorCode })
            }
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.hasCheckedCard ?
                        (
                            this.state.card ?
                                <CardDisplay card={this.state.card} onRemoveCard={this.onRemoveCard} /> :
                                <CardEditForm handleSubmit={this.handleSubmit} />
                        ) : <CircularProgress />
                }

                {
                    this.state.error == "pendingInvoices" &&
                    <Typography variant="h5" style={{ color: "#cc0000" }}>Impossible de supprimer la carte, des commandes à venir ne sont pas encore facturées</Typography>
                }
            </div>
        )
    }
}


export default function InjectedCardSetupForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardSetupForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    )
}