import React from "react"
import Layout from "../layout/Layout"
import Typography from '@material-ui/core/Typography'
import API from '../utils/API'
import CardSetupForm from "./CardSetupForm.js"
import InvoicesTable from './InvoicesTable.js'


export default class BillingPage extends React.Component {
    state = { invoices: [] }

    async componentDidMount() {
        const response = await API.retrieveInvoices()
        this.setState({ invoices: response.data.data })
        console.log("Invoices: " + JSON.stringify(response.data.data))
    }

    render() {
        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: "20px" }} className="HeaderText">
                    Ma facturation
                </Typography>
                <CardSetupForm />
                <InvoicesTable invoices={this.state.invoices} />
            </Layout>
        )
    }
}