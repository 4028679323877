import React from "react"
import { Typography, Box } from "@material-ui/core"
import Layout from "../layout/Layout"
import API from "../utils/API"
const ReactMarkdownWithHtml = require('react-markdown/with-html')


export default class InfoPage extends React.Component {
    state = { content: "" }

    async componentDidMount() {
        const { data } = await this.props.getPage()
        console.log("Result " + JSON.stringify(data))
        let markdown = data.content
        markdown = markdown.replace(/\\n/gi, '\n')
        this.setState({ content: markdown })
    }

    render() {
        
        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: 30, marginBottom: 60 }} className="HeaderText">
                    {this.props.pageTitle}
                </Typography>
                <Box style={{ width: 900, height: 600, margin: "auto", padding: 20, backgroundColor: "white", overflow: "auto" , borderRadius:5}}>
                    <ReactMarkdownWithHtml allowDangerousHtml >{this.state.content}</ReactMarkdownWithHtml>
                </Box>
            </Layout>
        )
    }
}