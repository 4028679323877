import React, { Component } from "react"
import { Route, Switch, withRouter } from "react-router-dom"

import Categories from "./transcode/Categories.js"
import Workflows from "./transcode/Workflows.js"
import TranscodePage from "./transcode/TranscodePage.js"

import OrderPage from "./orders/OrderPage.js"
import BillingPage from "./billing/BillingPage.js"

import "./App.css"

import Signup from "./user/Signup.js"
import InfoPage from "./user/InfoPage.js"
import LoginPage from "./user/LoginPage.js"
import AccountPage from "./user/AccountPage.js"
import DownloadPage from './user/DownloadPage.js'
import ResetPasswordPage from "./user/ResetPasswordPage.js"

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { PrivateRoute } from "./utils/PrivateRoute.js"
import API from "./utils/API.js"
require('dotenv').config()

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto'
    ].join(','),
  },});


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


// main app
class App extends Component {

  render() {
    return (
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
        <div className="App">
          <div className="App-content">
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/signup" component={Signup}/>
              <Route exact path="/reset" component={ResetPasswordPage}/>
              <Route exact path="/download/:id" component={DownloadPage}/>
              <Route exact path="/reset/:id" component={ResetPasswordPage}/>
            
              <Route exact path="/faq" render={(props) => (<InfoPage {...props} pageTitle="FAQ" getPage={API.getFAQPage}/>)} />
              <Route exact path="/pricing" render={(props) => (<InfoPage {...props} pageTitle="Tarifs" getPage={API.getPricingPage}/>)} />
              <Route exact path="/saleConditions" render={(props) => (<InfoPage {...props} pageTitle="Conditions générales de vente" getPage={API.getSaleConditionsPage}/>)} />
              <Route exact path="/useConditions" render={(props) => (<InfoPage {...props} pageTitle="Conditions générales d'utilisation" getPage={API.getUseConditionsPage}/>)} />

              <PrivateRoute path="/categories" component={Categories} />
              <PrivateRoute path="/options" component={Workflows} />
              <PrivateRoute path="/billing" component={BillingPage} />
              <PrivateRoute path="/transcode" component={TranscodePage} />
              <PrivateRoute path="/account" component={AccountPage} />
              <PrivateRoute path="/orders" component={OrderPage} />
            </Switch>
          </div>
        </div>
        </ThemeProvider>
        
      </Elements>
    );
  }
}
export default withRouter(App);