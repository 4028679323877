import React from "react"
import Layout from "../layout/Layout"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import API from "../utils/API"
import OrdersTable from "./OrdersTable"


export default class OrderPage extends React.Component {
    state = { orders: [] }

    async componentDidMount() {
        let orders = (await API.retrieveOrders()).data.orders
        orders.sort((o1,o2) => new Date(o2.date) - new Date(o1.date))

        console.log("All orders: " + JSON.stringify(orders))
        this.setState({ orders: orders })
    }

    render() {
        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: "20px" }} className="HeaderText">
                    Mes commandes
                </Typography>

                {/*<Button
                    disableRipple style={{ marginTop: "40px" }}
                    onClick={() => window.location = "/categories"}
                    variant="contained" size="large">
                    Nouvelle commande
                </Button>*/}

                <OrdersTable orders={this.state.orders}/>
            </Layout>
        )
    }
}