import React from "react"
import Header from "./Header"
import Footer from "./Footer"

export default class Layout extends React.Component {
    render() {
        return (       
                <div>
                    <Header />
                    <div style={{height:"100%", overflow:"hidden"}}>
                    {this.props.children}
                    </div>
                    <Footer />
                </div>
            )
    }
}