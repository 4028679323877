import React, { useState, useEffect, Fragment } from "react"
import Layout from "../layout/Layout"
import API from "../utils/API.js"
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Typography, Box } from '@material-ui/core'

// request new password
function PasswordRequest({ onValidate }) {
  const [email, setEmail] = useState("")
  return (
    <div>
      <FormGroup controlId="email" bsSize="large">
        <ControlLabel><Typography variant="h5">Email</Typography></ControlLabel>
        <FormControl autoFocus type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </FormGroup>
      <Button onClick={() => onValidate(email)} block bsSize="large" type="submit">
        <Typography variant="h4">Nouveau mot de passe</Typography>
      </Button>
    </div>
  )
}


// reset actual password
function PasswordReset({ onValidate }) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")

  return (

    <div>
      <FormGroup controlId="email" bsSize="large">
        <ControlLabel><Typography variant="h5">Email</Typography></ControlLabel>
        <FormControl autoFocus type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </FormGroup>

      <FormGroup controlId="password" bsSize="large">
        <ControlLabel><Typography variant="h5">Nouveau mot de passe</Typography></ControlLabel>
        <FormControl type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
      </FormGroup>

      <FormGroup controlId="cpassword" bsSize="large">
        <ControlLabel><Typography variant="h5">Confirmer le mot de passe</Typography></ControlLabel>
        <FormControl type="password" value={cpassword} onChange={(e) => setCPassword(e.target.value)}/>
      </FormGroup>

      <Button onClick={() => onValidate(email, password, cpassword)} block bsSize="large" type="submit">
        <Typography variant="h4">Valider</Typography>
      </Button>
    </div>
  )
}


export default class ResetPasswordPage extends React.Component {
  state = { email: "", result: "", resetPasswordId: "", requestSent: false, passwordReseted:false }

  requestNewPassword = async (email) => {
    console.log("Request password reset for " + email)
    const { data } = await API.requestResetPassword(email)
    console.log("Data received: " + JSON.stringify(data))
    localStorage.setItem("resetPasswordId", data.resetPasswordId)

    if (data.resetPasswordId !== undefined)
      this.setState({requestSent:true})
  }


  resetPassword = async (email, password, cpassword) => {

    if (!email || email.length === 0) return;
    if (!password || password.length === 0 || password !== cpassword) return;

    console.log("Reset password for " + email)
    const { data } = await API.resetPassword(email, password, localStorage.getItem("resetPasswordId"))
    console.log("Data: " + JSON.stringify(data))
    if (data.text == "Success")
      this.setState({passwordReseted:true})
  }


  render() {
    console.log("Params for reset: " + this.props.match.params.id)

    return (
      <Layout>
        <Box display="flex" minHeight="700px" flexDirection="row" p={1} m={1} alignItems="center" justifyContent="center">
          <Box p={1}>
            <div>
              <img src="/LOGO_BLANC_300.png" width="300px" />
            </div>
          </Box>

          <Box p={20} width="700px" height="500px">
            {this.props.match.params.id ? <PasswordReset onValidate={this.resetPassword} /> : <PasswordRequest onValidate={this.requestNewPassword} />}

            {
              this.state.requestSent &&
              <Typography variant="h5" style={{marginTop:20}}>Demande envoyée</Typography>
            }

            {
              this.state.passwordReseted &&
              <Typography variant="h5" style={{marginTop:20}}>Mot de passe réinitialisé</Typography>
            }
          </Box>
        </Box>
      </Layout>
    )
  }
}