import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    tableContainer: {
        width: 900,
        margin: "auto",
        marginTop: "60px",
        height: "600px"
    },
    tableCell: {
        fontSize: "16px",
        height: "30px",
        fontWeight: "normal"
    },
    tableCellHeader: {
        fontSize: "20px"
    },
})


function formatDate(dateStrISO) {
    return new Date(Date.parse(dateStrISO)).toLocaleString("fr-FR") // use timezone in dateStr?
}


function formatStatus(order) {
    const status = order.status
    if (status == "finalizing")
        return "Finalisation"
    else if (status == "running") {

        // it is actually cancelled even though we cannot see it yet
        if (order.inputs.every(f => f.status == "uploading"))
            return "Annulé"

        // average progress of all files
        const progressArray = order.inputs.filter(f => f.status != "uploading").map(i => i.progress)
        const sum = progressArray.reduce((a, b) => a + b, 0)
        const progressDisplay = Math.round(sum / progressArray.length)

        if (progressDisplay == 100)
            return "Finalisation"
        else return progressArray.length > 0 && !isNaN(progressDisplay) ? progressDisplay + "%" : "-"
    }
    else if (status == "complete") {
        //return (<Link href={order.zipDownloadUrl} style={{ height: "10px", cursor: "pointer" }} download>Télécharger</Link>)
        return (<Link onClick={() => window.open(`/download/${order._id}`)} style={{ height: "10px", cursor: "pointer" }} download>Télécharger</Link>)
    }
    else if (status == "expired")
        return "Expiré"
    else if (status == "error")
        return "Erreur"
    else if (status == "cancelled")
        return "Annulé"
    else return status
}


function formatMinutes(minutes) {
    return Math.round(minutes * 100) / 100
}


function OrderRow({ order }) {
    const classes = useStyles()
    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                {formatDate(order.date)}
            </TableCell>
            <TableCell className={classes.tableCell}>
                {order._id}
            </TableCell>
            <TableCell className={classes.tableCell}>
                {order.durationMinutes ? formatMinutes(order.durationMinutes) : "-"}
            </TableCell>
            <TableCell className={classes.tableCell}>
                {formatStatus(order)}
            </TableCell>
        </TableRow>
    )
}


export default function OrdersTable({ orders }) {
    const classes = useStyles()
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>Date</TableCell>
                        <TableCell className={classes.tableCellHeader}>Numéro de commande</TableCell>
                        <TableCell className={classes.tableCellHeader}>Minutes</TableCell>
                        <TableCell className={classes.tableCellHeader}>Statut</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map(o => <OrderRow key={o._id} order={o} />)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}