import React, { useState } from "react"
import API from "../utils/API";
import Url from "url-parse"
import Layout from "../layout/Layout"
import { Typography, Button, Box } from "@material-ui/core"
const ReactMarkdownWithHtml = require('react-markdown/with-html')

// one solution, not working properly (the last one only gets downloaded)
// I tried using timers without success
function appendAndClick(url, name) {
    console.log("Append and click")
    const a = document.createElement("a");
    a.href = url;
    a.style = "display: none";
    if (name && name.length)
        a.download = name;
    document.body.appendChild(a);
    a.click();
}


// might overcome cors, needs testing
function directFetch(url, name) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            if (name && name.length) a.download = name;
            document.body.appendChild(a);
            a.click();
        })
}


function getNameFromUrl(url) {
    let name = new Url(url).pathname.substring(1)
    name = decodeURI(/[^/]*$/.exec(name)[0])
    return name
}


function download(inputs) {
    for (const input of inputs) {
        if (input.status == "error")
            continue

        const url = input.downloadUrl
        const name = getNameFromUrl(url)

        console.log(`Downloading: ${name} at ${url}`)
        window.open(url, "_blank")
    }
}


function formatButtonText(nb) {
    return nb > 1 ? `Télécharger ${nb} fichiers` : "Télécharger 1 fichier"
}


// this is called with an order id and download all completed files
export default class DownloadPage extends React.Component {
    state = { inputs: [] }

    async componentDidMount() {
        const orderId = this.props.match.params.id
        console.log(`Downloading all files for order ${orderId}`)

        const { data } = await API.retrieveOrder(orderId)

        this.setState({ inputs: data.inputs.filter(f => f.status == "complete") })

        // gets the content from server
        const pageContent = await API.getDownloadPage()
        console.log("Result " + JSON.stringify(pageContent.data))
        let markdown = pageContent.data.content
        markdown = markdown.replace(/\\n/gi, '\n')
        this.setState({ content: markdown })
    }

    render() {
        return (<Layout>

            <Typography variant="h2" style={{ marginTop: 30, marginBottom: 30 }} className="HeaderText">
                Vos fichiers sont prêts
            </Typography>

            <Box style={{ width: 1000, margin: "auto", padding: 20, backgroundColor: "white" }}>
                <ReactMarkdownWithHtml allowDangerousHtml >{this.state.content}</ReactMarkdownWithHtml>
            </Box>

            {
                this.state.inputs.length > 0 &&
                <Button
                    component="label"
                    variant="contained"
                    onClick={() => download(this.state.inputs)}
                    disableRipple style={{ marginTop: "40px", height: "50px" }}>
                    <Typography variant="h5">
                        {formatButtonText(this.state.inputs.length)}
                    </Typography>
                </Button>
            }

        </Layout>)
    }
}