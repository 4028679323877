import React from "react"
import Layout from "../layout/Layout"
import API from "../utils/API"

import { CardContent, CardActionArea, Card, Typography, Grid, Box } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'


const useStyles = theme => ({
    card: {
        //maxWidth: 345,
        width: 345,
        height: 150
    },
    media: {
        height: 140,
    },
})


class Category extends React.Component {

    onClick = () => {
        localStorage.setItem("category", this.props.category.Identifier)
        window.location = "/options"
    }

    render() {
        const { classes, category } = this.props

        return (
            <Card className={classes.card} onClick={this.onClick}>
                <CardActionArea style={{ height: "100%" }}>
                    <CardContent >
                        <Typography variant="h3" >
                            {category.Name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
}

class Categories extends React.Component {

    state = { Categories: [] }

    async componentDidMount() {
        const response = await API.getCategories()
        var categories = response.data.Categories
        categories.sort((c1,c2) => c1.Name < c2.Name ? -1 : 1)
        this.setState({ Categories: categories })
    }

    render() {
        const { classes } = this.props

        console.log("Current categories: " + JSON.stringify(this.state.Categories))
        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: 30 }} className="HeaderText">
                    Que souhaitez-vous faire de vos fichiers ?
                </Typography>

                <Grid item xs={12} style={{ marginTop: 50 }}>
                    <Grid container justify="center" spacing={2}>
                        {
                            this.state.Categories.map(cat =>
                                <Grid key={cat.Identifier} item>
                                    <Category classes={classes} category={cat} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>

            </Layout>)
    }
}

export default withStyles(useStyles)(Categories)