import React from "react"
import Layout from "../layout/Layout"
import API from "../utils/API"
import { CardContent, CardActionArea, Card, Typography, Grid, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
const ReactMarkdownWithHtml = require('react-markdown/with-html')

const useStyles = theme => ({
    card: {
        width: 345,
        height: 150
    },
    media: {
        height: 140,
    }
})


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(20),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


class Workflow extends React.Component {

    onClick = () => {
        localStorage.setItem("workflow", this.props.workflow.Identifier)
        window.location = "/transcode"
    }

    render() {
        const { classes, workflow } = this.props
        if (workflow.Description === undefined)
            return (
                <Card className={classes.card} onClick={this.onClick}>
                    <CardActionArea style={{ height: "100%" }}>
                        <CardContent>
                            <Typography variant="h3">
                                {workflow.Name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card >
            )
        else return (
            <Card className={classes.card} onClick={this.onClick}>
                <HtmlTooltip title={<ReactMarkdownWithHtml allowDangerousHtml >{workflow.Description}</ReactMarkdownWithHtml>} enterDelay={600} enterNextDelay={200}>
                    <CardActionArea style={{ height: "100%" }}>
                        <CardContent>
                            <Typography variant="h3">
                                {workflow.Name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </HtmlTooltip>
            </Card >
        )
    }
}


class Workflows extends React.Component {

    state = { Workflows: [] }

    async componentDidMount() {
        const category = localStorage.getItem("category")
        const response = await API.getActiveWorkflowsByCategory(category)
        var workflows = response.data.Workflows
        workflows.sort((c1,c2) => c1.Name < c2.Name ? -1 : 1)

        this.setState({ Workflows:workflows })
        console.log("Workflows: " + JSON.stringify(response.data.Workflows) + " Category: " + JSON.stringify(category))
    }

    render() {
        const { classes } = this.props
        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: 30 }} className="HeaderText">
                    Vos options
                </Typography>
                <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Grid container justify="center" spacing={2}>
                        {
                            this.state.Workflows.map(w =>
                                <Grid key={w.Identifier} item>
                                    <Workflow classes={classes} workflow={w} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Layout>)
    }
}

export default withStyles(useStyles)(Workflows)