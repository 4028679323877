import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBack from '@material-ui/icons/ArrowBack';
import AccountCircle from '@material-ui/icons/AccountCircle';

import API from "../utils/API.js"
import { useHistory } from "react-router-dom"
import { Typography, Toolbar, AppBar, Button, IconButton, Menu, MenuItem } from "@material-ui/core"


const useStyles = makeStyles((theme) => ({
  root: {

  },
  
  backArrow: {
    marginLeft: 20,
    marginRight: theme.spacing(2),
    fontSize: "200%"
  },

  menuItem: {
    fontSize: "14px"
  },

  homeButton: {
    width: 100,
    cursor: "pointer"
  },

  title: {
    flexGrow: 1,
    color: "black"
  },
}));



function AppMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => { setAnchorEl(event.currentTarget) }

  const handleClose = () => { setAnchorEl(null) }

  const disconnect = () => {
    API.logout()
    window.location = "/"
  }
  const classes = useStyles()

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="default"
      >
        <AccountCircle style={{ fontSize: "250%" }} />
      </IconButton>

      <Menu
        keepMounted
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>

        <MenuItem className={classes.menuItem} onClick={() => window.location = "/account"}>Mon compte</MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => window.location = "/orders"}>Mes commandes</MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => window.location = "/billing"}>Ma facturation</MenuItem>
        <MenuItem className={classes.menuItem} onClick={disconnect}>Se déconnecter</MenuItem>
      </Menu>
    </div>
  )

}


function logo()
{
  if (localStorage.getItem("trial") == "true")
    return "/LOGO HEADER_72_TRIAL.png"
  else return "/LOGO HEADER_72.png"
}



export default function Header(props) {
  const classes = useStyles();
  let history = useHistory()

  function isPublicRoute() {
    return history.location.pathname == "/" || 
    history.location.pathname == "/signup" || 
    history.location.pathname == "/reset" || 
    history.location.pathname == "/reset" ||
    history.location.pathname == "/reset"
  }

  const onHomeClick = () => {
    if (isPublicRoute())
      window.location = "/"
    else window.location = "/categories"
  }

  return (
    
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "white" }} elevation={0}>
        <Toolbar>

          <img src={logo()} className={classes.homeButton} onClick={onHomeClick} />

          {
            history.location.pathname != "/" && !history.location.pathname.startsWith("/download") &&
            <IconButton edge="start" className={classes.backArrow} color="default" onClick={history.goBack}>
              <ArrowBack style={{ fontSize: "110%" }} />
            </IconButton>
          }

          <Typography variant="h3" className={classes.title}>
            {/*CotCotCodec {localStorage.getItem("trial") == "true" && history.location.pathname != "/" && "(Trial)"}*/}
          </Typography>*

          <Typography 
              variant="h5" 
              onClick={() => window.location="/faq"}
              style={{ color: "black", cursor:"pointer" }}>
            FAQ
          </Typography>

          <Typography 
              variant="h5" 
              onClick={() => window.location="/pricing"}
              style={{ color: "black", marginLeft: "20px", marginRight: "20px", cursor:"pointer" }} >
            Tarifs
          </Typography>

          {(API.isAuth()) && <AppMenu />}

        </Toolbar>
      </AppBar>
    </div>
  );
}