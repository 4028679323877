import React from "react"
import API from "./API.js"
import { Route, Redirect } from "react-router-dom"

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (API.isAuth() === false)
        return <Redirect to="/" />;
      else {
       // console.log("All: "+JSON.stringify(props)+" Rest: "+JSON.stringify(rest))
        return <Component {...props} {...rest} />}
    }}
  />
)