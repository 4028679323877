import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
    tableContainer: {
        width: 900,
        margin: "auto",
        marginTop: "60px",
        height: "500px"
    },
    tableCell: {
        fontSize: "16px",
        height: "30px"
    },
    tableCellHeader: {
        fontSize: "20px"
    },
})


function InvoiceRow({ invoice }) {
    const classes = useStyles()
    return (
        <TableRow>
            <TableCell className={classes.tableCell} >
                {invoice.id}
            </TableCell>
            <TableCell className={classes.tableCell} >
                {invoice.amount_due / 100}€
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
                {
                    invoice.invoice_pdf ?
                        <Link href={invoice.invoice_pdf} target="_blank">PDF</Link> :
                        <Typography variant="h5">A venir</Typography>
                }

            </TableCell>
        </TableRow>
    )
}


export default function InvoicesTable({ invoices }) {
    const classes = useStyles()
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>Numéro</TableCell>
                        <TableCell className={classes.tableCellHeader} >Montant</TableCell>
                        <TableCell className={classes.tableCellHeader} align="right">PDF</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        invoices.map(i => <InvoiceRow key={i.id} invoice={i} />)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
