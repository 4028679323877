import React from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import API from "../utils/API";
import Layout from "../layout/Layout"
import { Link, Box, Typography } from '@material-ui/core'
import NavbarCollapse from "react-bootstrap/lib/NavbarCollapse";

export default class LoginPage extends React.Component {
  state = { email: "", password: "", error: "" }

  send = async () => {
    const { email, password } = this.state;
    if (!email || email.length === 0)
    {
      this.setState({error: "Missing email"})
      return
    }
      
    if (!password || password.length === 0)
    {
      this.setState({error: "Missing password"})
      return
    }

    try {
      const { data } = await API.login(email, password)
      localStorage.setItem("token", data.token)
      localStorage.setItem("trial", data.trial)

      console.log("Trial: " + data.trial)

      window.location = "/categories"
    } catch (e) {
      if (e.response && e.response.data)
        this.setState({ error: e.response.data.message })
    }
  }


  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value, error:"" })
  }


  render() {
    const { email, password } = this.state

    if (localStorage.getItem("token"))
    {
        window.location = "/categories"
        return (<div/>)
    }

    return (
      <Layout>
        <Box display="flex" minHeight="700px" flexDirection="row" p={1} m={1} alignItems="center" justifyContent="center">
          <Box p={1}>
            <div>
              <img src="./LOGO_BLANC_300.png" width="300px" />
            </div>
          </Box>

          <Box p={20} width="700px">
            <div>
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel><Typography variant="h5">Email</Typography></ControlLabel>
                <FormControl autoFocus type="email" value={email} onChange={this.handleChange} />
              </FormGroup>
              <FormGroup controlId="password" bsSize="large">
                <ControlLabel><Typography variant="h5">Mot de passe</Typography></ControlLabel>
                <FormControl type="password" value={password} onChange={this.handleChange} />
              </FormGroup>
              <Box display="flex" justifyContent="center" alignItems="center">
                {
                  this.state.error != "" &&
                  <Typography variant="h5" style={{color:"#cc0000"}}>Données de connexion incorrectes&nbsp;</Typography>
                }

                <Link href="/reset">Mot de passe oublié ?</Link>
              </Box>
              <Button style={{ marginTop: "20px" }} onClick={this.send} block bsSize="large" type="submit">
                <Typography variant="h4">Connexion</Typography>
              </Button>
              <Button onClick={() => window.location = "/signup"} block bsSize="large" type="submit">
                <Typography variant="h4">Créer un compte</Typography>
              </Button>
            </div>
          </Box>
        </Box>
      </Layout>
    )
  }
}