import React from "react"
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap"
import API from "../utils/API"
import Layout from "../layout/Layout"
import { Typography, Box } from '@material-ui/core'


function validateEmail(inputText) {
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return inputText.match(mailformat)
}


export default class Signup extends React.Component {
  state = { email: "", password: "", cpassword: "", error: "" }

  send = async () => {
    const { email, password, cpassword } = this.state

    if (!email || email.length === 0 || !validateEmail(email)) {
      this.setState({ error: "Email incorrect" })
      return
    }

    if (!password || password.length === 0 || password !== cpassword) {
      this.setState({ error: "Mot de passe incorrect" })
      return
    }

    try {
      const { data } = await API.signup({ email, password })
      localStorage.setItem("token", data.token)
      localStorage.setItem("signing", "true")
      window.location = "/account"
    } catch (error) {
      console.error(error)
      this.setState({ error: "Email ou mot de passe incorrect" })
    }
  }


  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value, error: ""
    })
  }


  render() {
    const { email, password, cpassword } = this.state
    return (
      <Layout>
        <Box display="flex" minHeight="700px" flexDirection="row" p={1} m={1} alignItems="center" justifyContent="center">
          <Box p={1}>
            <div>
              <img src="./LOGO_BLANC_300.png" width="300px" />
            </div>
          </Box>

          <Box p={20} width="700px">
            <div>
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel><Typography variant="h5">Email</Typography></ControlLabel>
                <FormControl autoFocus type="email" value={email} onChange={this.handleChange} />
              </FormGroup>

              <FormGroup controlId="password" bsSize="large">
                <ControlLabel><Typography variant="h5">Mot de passe</Typography></ControlLabel>
                <FormControl type="password" value={password} onChange={this.handleChange} />
              </FormGroup>

              <FormGroup controlId="cpassword" bsSize="large">
                <ControlLabel><Typography variant="h5">Confirmer le mot de passe</Typography></ControlLabel>
                <FormControl type="password" value={cpassword} onChange={this.handleChange} />
              </FormGroup>

              {
                this.state.error != "" &&
                <Typography variant="h5" style={{ color: "#cc0000" }}>{this.state.error}</Typography>
              }

              <Button onClick={this.send} block bsSize="large" type="submit">
                <Typography variant="h4">Valider</Typography>
              </Button>
            </div>
          </Box>
        </Box>

      </Layout>
    );
  }
}