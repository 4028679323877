import React from "react"
import API from "../utils/API.js"
import Layout from "../layout/Layout"
//import { Typography, Box, FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core/'
import { Button, FormGroup, FormControl, ControlLabel, Row, Form, Col } from "react-bootstrap";
import { Typography, Box, TextField, ThemeProvider } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles';

function field(value) {
    return value === undefined || value == null ? "" : value
}


// returns true if all elements of a user are defined
export function userComplete(user) {
    if (user.lastName &&
        user.firstName &&
        user.email &&
        user.company &&
        user.address &&
        user.city &&
        user.postalCode &&
        user.country)
        return true
    else return false
}


export default class AccountPage extends React.Component {
    state = {
        lastName: "",
        firstName: "",
        email: "",
        company: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        error: "",
        saved: false
    }

    async componentDidMount() {
        const { data } = await API.getPersonalData()
        console.log("Received: " + JSON.stringify(data))

        this.setState({
            lastName: field(data.lastName),
            firstName: field(data.firstName),
            email: field(data.email),
            company: field(data.company),
            address: field(data.address),
            city: field(data.city),
            postalCode: field(data.postalCode),
            country: field(data.country),
            saved: false
        })
    }

    onSave = async () => {

        // that's quite ugly
        if (this.state.lastName == "") {
            this.setState({ error: "Veuillez saisir votre nom de famille" })
            return
        }

        if (this.state.firstName == "") {
            this.setState({ error: "Veuillez saisir votre prénom" })
            return
        }

        if (this.state.email == "") {
            this.setState({ error: "Veuillez saisir votre email" })
            return
        }

        if (this.state.company == "") {
            this.setState({ error: "Veuillez saisir votre entreprise" })
            return
        }

        if (this.state.address == "") {
            this.setState({ error: "Veuillez saisir votre addresse" })
            return
        }

        if (this.state.city == "") {
            this.setState({ error: "Veuillez saisir votre ville" })
            return
        }

        if (this.state.postalCode == "" || isNaN(this.state.postalCode)) {
            this.setState({ error: "Veuillez saisir votre code postal" })
            return
        }

        if (this.state.country == "") {
            this.setState({ error: "Veuillez saisir votre pays" })
            return
        }

        const { data } = await API.setPersonalData(this.state)
        console.log("Response: " + JSON.stringify(data))

        if (localStorage.getItem("signing") == "true") {
            localStorage.setItem("signing", "false")
            window.location = "/categories"
        }
        else this.setState({ saved: true })
    }


    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value, error: "", saved: false })
    }

    render() {

        return (
            <Layout>
                <Typography variant="h2" style={{ marginTop: "20px" }} className="HeaderText">
                    Mon compte
                </Typography>

                <Box style={{ width: 500, margin: "auto", marginTop: "30px" }}>

                    <Box display="flex">
                        <FormGroup controlId="firstName" bsSize="large">
                            <ControlLabel><Typography variant="h5">Prénom</Typography></ControlLabel>
                            <FormControl value={this.state.firstName} onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup controlId="lastName" bsSize="large">
                            <ControlLabel><Typography variant="h5">Nom</Typography></ControlLabel>
                            <FormControl value={this.state.lastName} onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup controlId="email" bsSize="large">
                            <ControlLabel><Typography variant="h5">Email</Typography></ControlLabel>
                            <FormControl value={this.state.email} onChange={this.handleChange} />
                        </FormGroup>
                    </Box>


                    <FormGroup controlId="company" bsSize="large">
                        <ControlLabel><Typography variant="h5">Entreprise</Typography></ControlLabel>
                        <FormControl value={this.state.company} onChange={this.handleChange} />
                    </FormGroup>



                    <FormGroup controlId="address" bsSize="large">
                        <ControlLabel><Typography variant="h5">Addresse</Typography></ControlLabel>
                        <FormControl value={this.state.address} onChange={this.handleChange} />
                    </FormGroup>


                    <Box display="flex">
                        <FormGroup controlId="city" bsSize="large">
                            <ControlLabel><Typography variant="h5">Ville</Typography></ControlLabel>
                            <FormControl value={this.state.city} onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup controlId="postalCode" bsSize="large">
                            <ControlLabel><Typography variant="h5">Code postal</Typography></ControlLabel>
                            <FormControl value={this.state.postalCode} onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup controlId="country" bsSize="large">
                            <ControlLabel><Typography variant="h5">Pays</Typography></ControlLabel>
                            <FormControl value={this.state.country} onChange={this.handleChange} />
                        </FormGroup>
                    </Box>

                    {
                        this.state.error != "" &&
                        <Typography variant="h5" style={{ color: "#cc0000" }}>{this.state.error}</Typography>
                    }

                    <Button onClick={this.onSave} block bsSize="large" type="submit" style={{ marginTop: 20 }}>
                        <Typography variant="h4">Sauvegarder</Typography>
                    </Button>

                    {
                        this.state.saved &&
                        <Typography variant="h5" style={{ marginTop: 20 }}>Données personnelles sauvegardées</Typography>
                    }
                </Box>

            </Layout>
        )
    }
}