import axios from "axios"
require('dotenv').config()

const burl = process.env.REACT_APP_BACKEND_URL


console.log("Using backend url: " + burl)


function headers() {
  const token = localStorage.getItem("token")
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  }
}

function noAuthHeaders() {
  return { headers: { "Content-Type": "application/json" } }
}


export default {

  ///////////////// no api (we could move that elsewhere)
  isAuth: function () {
    return localStorage.getItem("token") !== null // isnt it weird?
  },

  logout: function () {
    localStorage.clear()
  },

  ////////////////////////////////// NO AUTH SECTION

  ///////////////// user no auth

  login: function (email, password) {
    return axios.post(`${burl}/user/login`, { email, password }, noAuthHeaders())
  },

  signup: function (send) {
    console.log("Signing up")
    return axios.post(`${burl}/user/signup`, send, noAuthHeaders())
  },

  requestResetPassword: function (email) {
    return axios.post(`${burl}/user/reset-request`, { email }, noAuthHeaders())
  },

  resetPassword: function (email, password, resetPasswordId) {
    return axios.post(`${burl}/user/reset`, { email, password, resetPasswordId }, noAuthHeaders())
  },


  ///////////////// info no auth
  getFAQPage: function () {
    return axios.post(`${burl}/info/faq`, {}, noAuthHeaders())
  },

  getPricingPage: function () {
    return axios.post(`${burl}/info/pricing`, {}, noAuthHeaders())
  },

  getUseConditionsPage: function () {
    return axios.post(`${burl}/info/use-conditions`, {}, noAuthHeaders())
  },

  getSaleConditionsPage: function () {
    return axios.post(`${burl}/info/sale-conditions`, {}, noAuthHeaders())
  },

  getDownloadPage: function () {
    return axios.post(`${burl}/info/download`, {}, noAuthHeaders())
  },


  ////////////////////////////////// AUTH SECTION

  ///////////////// user auth
  getPersonalData: function () {
    return axios.post(`${burl}/user/data/get`, {}, headers())
  },

  setPersonalData: function (data) {
    return axios.post(`${burl}/user/data/set`, data, headers())
  },


  ///////////////// billing
  requestSetupIntent: function () {
    return axios.post(`${burl}/billing/setup-intent`, {}, headers())
  },

  retrieveCard: function () {
    return axios.post(`${burl}/billing/retrieve-card`, {}, headers())
  },

  removeCard: function () {
    return axios.post(`${burl}/billing/remove-card`, {}, headers())
  },

  setDefaultCard: function () {
    return axios.post(`${burl}/billing/set-default-card`, {}, headers())
  },

  retrieveInvoices: function () {
    return axios.post(`${burl}/billing/retrieve-invoices`, {}, headers())
  },

  isValid: function () {
    return axios.post(`${burl}/billing/is-valid`, {}, headers())
  },


  ///////////////// orders

  // files is an array of {filename:filename, contentType:contentType}
  requestTranscodeOrder: function (files, workflowId, recipients) {
    return axios.post(`${burl}/orders/request`, { files, workflowId, recipients }, headers())
  },

  retrieveOrder: function (orderId) {
    return axios.post(`${burl}/orders/${orderId}`, {}, headers())
  },

  retrieveOrders: function () {
    return axios.post(`${burl}/orders/`, {}, headers())
  },

  cleanupOrders: function() {
    return axios.post(`${burl}/orders/cleanup`, {}, headers())
  },

  sendMails: function (orderId) {
    return axios.post(`${burl}/orders/${orderId}/email`, {}, headers())
  },

  onUploadFinished: function (orderId, file) {
    return axios.post(`${burl}/orders/${orderId}/upload-finished`, { file }, headers())
  },

  generateUploadPart: function (fileName, partNumber, uploadId) {
    return axios.post(`${burl}/orders/generate-uploadpart`,
      { Key: fileName, PartNumber: partNumber, UploadId: uploadId }, headers())
  },

  completeUpload: function (fileName, parts, uploadId) {
    return axios.post(`${burl}/orders/complete-upload`,
      { Key: fileName, Parts: parts, UploadId: uploadId }, headers())
  },

  keepAlive: function(orderId) {
    return axios.post(`${burl}/orders/${orderId}/keep-alive`, {}, headers())
  },

  ///////////////// workflows

  getWorkflow: function (workflowId) {
    return axios.post(`${burl}/workflows/${workflowId}`, {}, headers())
  },

  getCategories: function () {
    return axios.post(`${burl}/categories`, {}, headers())
  },

  getActiveWorkflowsByCategory: function (categoryId) {
    return axios.post(`${burl}/categories/${categoryId}/active_workflows`, {}, headers())
  },
}