import { Box, Button, Typography, TextField, Checkbox, Link } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from "@material-ui/core/IconButton"
import React, { useState } from 'react'


function isValidEmail(mail) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(mail)
}


// a small widget to edit mail addresses and a Validate Button
// note that we allow mails to be empty if we just want the order for ourselves
export default function UploadMail({ onValidateOrder, isProcessingOrder, isOrderValid }) {
    const [error, setError] = useState(false)
    const [emails, setEmails] = useState([])
    const [readCheck, setReadCheck] = React.useState(false);
    const [currentEmail, setCurrentEmail] = useState("")

    const onKeyPressed = e => {
        if (e.key === 'Enter') {
            e.preventDefault()
            if (isValidEmail(currentEmail)) {
                emails.push(currentEmail)
                setEmails(emails)
                setCurrentEmail("")
            }
            else setError(true)
        }
    }

    const onRemoveEmail = (email) => {
        setEmails(emails.filter(e => e != email))
    }

    const onChange = (e) => {
        setError(false)
        setCurrentEmail(e.target.value)
    }

    const onValidate = () => {
        if (isValidEmail(currentEmail)) {
            emails.push(currentEmail)
            setEmails(emails)
        }
        setCurrentEmail("")
        onValidateOrder(emails)
    }

    return (
        <Box style={{ marginTop: "40px" }} flexDirection="column" display="flex" justifyContent="center" alignItems="center">
            <Box>
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                    {
                        emails.map(e =>
                            <Box key={e} display="flex" alignItems="center" height="25px" width="100%">
                                <Typography variant="h5" >{e}</Typography>
                                {
                                    !isProcessingOrder &&
                                    <IconButton onClick={() => onRemoveEmail(e)}>
                                        <ClearIcon />
                                    </IconButton>
                                }
                            </Box>)
                    }
                </Box>

                <TextField
                    error={error}
                    onChange={onChange}
                    value={currentEmail}
                    variant="outlined"
                    disabled={isProcessingOrder || !isOrderValid}
                    onKeyPress={onKeyPressed}
                    style={{ marginTop: "10px", backgroundColor: "white" }}
                    label={error ? "Erreur" : "Envoyer à"}
                    inputProps={{ style: { fontSize: 15 } }}
                    InputLabelProps={{ style: { fontSize: 13 } }}
                    helperText={error ? "Entrez une adresse valide" : ""} />
            </Box>

            <Box display="flex" alignItems="center" style={{marginTop:30, marginBottom:20}}>
                <Checkbox
                    checked={readCheck}
                    color="primary"
                    onChange={e => setReadCheck(e.target.checked)}
                    disabled={isProcessingOrder || !isOrderValid}
                />
                <Typography variant="h6">Je reconnais avoir lu les conditions générales de vente <Link href="/saleConditions" style={{cursor:"pointer"}}>ici</Link></Typography>
            </Box>

            <Button
                disableRipple
                size="large"
                variant="contained"
                disabled={!isOrderValid || isProcessingOrder || !readCheck}
                style={{ marginTop: "10px", height: "50px" }}
                onClick={() => onValidate()}>
                <Typography variant="h5">
                    Valider
                </Typography>
            </Button>
        </Box >
    )
}