import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from "@material-ui/core/IconButton"
import LinearProgress from "@material-ui/core/LinearProgress"
import Link from '@material-ui/core/Link'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import fileDownload from 'js-file-download'
import axios from "axios";
import Url from 'url-parse'
import Button from '@material-ui/core/Button'


const useStyles = makeStyles({
    tableCell: {
        fontSize: "16px",
        height: "30px"
    },
    tableCellHeader: {
        fontSize: "20px"
    },
    tableContainer: {
        width: 950,
        margin: "auto",
        marginTop: "40px",
        maxHeight: "340px"
    },

    progressBar: {
        width: 220,
        height: 20,
        marginRight: 10
    },

    barNoTransition: {
        transition: 'none'
    },

    colorPrimary: {
        backgroundColor: "#f2e4da",
    },
    barColorPrimary: {
        backgroundColor: "#cca789",
    }
});


function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}


// this fix is meant to prevent transitioning from 100 to 0
function ProgressBar({ progress }) {
    const classes = useStyles()
    if (progress > 0) {
        return (
            <LinearProgress className={classes.progressBar} classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} variant="determinate" value={progress} />
        );
    }
    return (
        <LinearProgress className={classes.progressBar} classes={{ bar: classes.barNoTransition, colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} variant="determinate" value={progress} />
    );
}

function computeProgress(file) {
    if (file == undefined)
        return 0

    if (file.status === "transcoding")
        return file.progress
    else {
        const multiProgress = file.progressMultiparts

        if (multiProgress == undefined || multiProgress.length == 0)
            return 0

        var sum = 0
        for (var i = 0; i < multiProgress.length; i++)
            sum += multiProgress[i]
        return Math.round(sum / multiProgress.length)
    }

    //console.log("Progress: "+JSON.stringify(fileProgress))
}


function cropLongFilename(filename) {
    if (filename.length < 50)
        return filename
    else return filename.substring(0, 50) + "..."
}


export default function UploadTable({ files, onRemoveFile }) {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>

            <Table className={classes.table} size="small" aria-label="simple table">
                <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>File</TableCell>
                        <TableCell className={classes.tableCellHeader}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map((f) => (
                        <TableRow key={f.id}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                                {cropLongFilename(f.file.name)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {
                                    f.status == "ready" &&
                                    <IconButton onClick={() => onRemoveFile(f)}>
                                        <ClearIcon />
                                    </IconButton>
                                }

                                {
                                    (f.status == "uploading" || f.status == "transcoding") &&
                                    <Box display="flex" alignItems="center" width="320px">
                                        <p style={{ height: "10px", width: "150px" }}>{capitalize(f.status)}</p>
                                        <ProgressBar width="150px" progress={computeProgress(f)} />
                                        <p style={{ height: "10px", width: "20px" }}>{computeProgress(f)}%</p>
                                    </Box>
                                }

                                {
                                    (f.status == "error") &&
                                    <Box display="flex" alignItems="center">
                                        <p style={{ height: "10px", marginRight: "10px" }}>Erreur</p>
                                    </Box>
                                }

                                {
                                    f.status == "complete" &&
                                    <Link style={{ height: "10px", cursor: "pointer" }} href={f.downloadUrl} download>Télécharger</Link>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}



